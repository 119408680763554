import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/Blog.vue"),
    beforeEnter(to, from, next) {
      window.location.href = "https://www.fundraising.co.ke/";
    }
  },
  {
    path: "/champions",
    name: "Champions",
    component: () => import("../views/Champions.vue"),
  },
  {
    path: "/communities",
    name: "Communities",
    component: () => import("../views/Communities.vue"),
  },
  {
    path: "/mchanga-communities",
    name: "MchangaCommunities",
    component: () => import("../views/mchanga-communities.vue"),
  },
  {
    path: "/mchanga-community/:id",
    name: "MedicalCommunities",
    component: () => import("../views/medical-communities.vue"),
  },
  {
    path: "/mobile-fundraiser",
    name: "MobileFundraiser",
    component: () => import("../views/mobile-fundraiser.vue"),
  },
  {
    path: '/online',
    redirect: '/online-fundraiser'
  },
  {
    path: "/online-fundraiser",
    name: "OnlineFundraiser",
    component: () => import("../views/online-fundraiser.vue"),
  },
  {
    path: "/resources",
    name: "Resources",
    component: () => import("../views/resources.vue"),
  },
  {
    path: "/fundraiser/:id/:response?",
    name: "Share",
    component: () => import("../views/share.vue"),
  },
  {
    path: "/signup",
    name: "Register",
    beforeEnter(to, from, next) {
      window.location.href = "https://accounts.mchanga.africa/signup";
    }
  },
  {
    path: "/start",
    name: "Start",
    beforeEnter(to, from, next) {
      window.location.href = "https://accounts.mchanga.africa/signup";
    }
  },
  {
    path: "/personal-detail",
    name: "PersonalDetail",
    component: () => import("../views/personal-detail.vue"),
  },
  {
    path: "/activate-your-fundraiser",
    name: "ActivateYourFundraiser",
    component: () => import("../views/activate-your-fundraiser.vue"),
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter(to, from, next) {
      window.location.href = "https://accounts.mchanga.africa/login";
    }
  },
  {
    path: '/questions',
    redirect: '/faq'
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("../views/faq.vue"),
  },
  {
    // /search/fundraiserName -> /search?q=fundraiserName
    path: '/search/:fundraiserName',
    redirect: to => {
      return { path: '/search', query: { q: to.params.fundraiserName } }
    },
  },
  {
    path: '/credit-cards',
    name: "CreditCards",
    component: () => import("../views/credit-card.vue"),
  },
  {
    path: '/commitment',
    name: "MChangaCommitment",
    component: () => import("../views/mchanga-commitment.vue"),
  },
  {
    path: '/rights-responsibilities',
    name: "MChangaRights",
    component: () => import("../views/rights-and-responsibilites.vue"),
  },
  {
    path: '/terms-of-use',
    name: "MCHANGATerms",
    component: () => import("../views/terms-of-use.vue"),
  },
  {
    path: '/privacy-policy',
    name: "PrivacyPolicy",
    component: () => import("../views/privacy-policy.vue"),
  },
  {
    path: '/fwave-donation-confirm/:status?/:tx_ref?/:transaction_id?',
    name: "Payments",
    component: () => import("../views/payments.vue"),
  },
  {
    path: '/mchanga-affiliates',
    name: "MChangaAffiliates",
    component: () => import("../views/AffiliateReg.vue"),
  },
  {
    path: '/mchanga-affiliate-terms',
    name: "MChangaAffiliatesTerms",
    component: () => import("../views/AffiliateTerms.vue"),
  },
  {
    path: '/affiliate-tracker/:refID',
    name: "MChangaAffiliateTracker",
    component: () => import("../views/AffiliateTracker.vue"),
  },
  {
    path: '/tripple-transaction/:tee_action',
    name: "TrippleCheckout",
    component: () => import("../views/TrippleCheckout.vue"),
  },
  {
    path: '/ipay-transaction',
    name: "IPAYTRANSACTION",
    component: () => import("../views/IpayCheckout.vue"),
  },
  {
    path: '/affiliate-sdg/:refID',
    name: "MChangaAffiliateSDG",
    component: () => import("../views/SDGTracker.vue"),
  },
  {
    path: '/champ-data',
    name: "MChangaChampionData",
    component: () => import("../views/champData.vue"),
  },
  {
    path: '/mcl-donation/:id/:response?',
    name: "MChangaDonorPortal",
    component: () => import("../views/fundraiserIframe.vue"),
  },
  {
    path: '/fees',
    name: "MChangaFees",
    component: () => import("../views/changaFees.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    name: "Home",
    component: Home
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    return { top: 0 }
  }
});

export default router;
