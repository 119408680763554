<template>
  <Head>
    <title>Mobile & Online Fundraising For Africa | M-Changa Africa</title>
    <meta
      name="description"
      content="M-Changa is Africa's largest Online Fundraising Platform. We provide the most Secure, Transparent & Convenient way to raise funds."
    />
    <meta name="author" content="Mobi Changa" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta
      property="og:image"
      content="https://res.cloudinary.com/changa/image/upload/c_scale,f_auto,q_auto,w_1200,h_630/v1649771541/web/Africalogo.png"
    />
    <meta
      property="og:title"
      content="M-Changa Africa: Secure, Transparent & Convenient Fundraising!"
    />
    <meta
      property="og:description"
      content="M-Changa is Africa's largest Online Fundraising Platform. We provide the most Secure, Transparent & Convenient way to raise funds."
    />
    <meta property="og:type" content="website" />
    <meta property="og:url" :content="this.baseURL" />
    <meta property="fb:app_id" :content="this.facebookID" />
    <meta
      name="facebook-domain-verification"
      content="vgho8toqh2ksls8x3ah2q4vo81m9cx"
    />
  </Head>
  <div>
    <section id="slider" class="slider-element home-slider mb-10">
      <div class="texture-overlay">
        <div class="texture-left">
          <img
            src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117724/web/zigzag-texture.svg"
          />
        </div>
        <div class="texture-right">
          <img
            src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117724/web/zigzag-texture.svg"
          />
        </div>
      </div>
      <div class="curve-overlay">
        <img
          src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/banner-curve-overlay.png"
        />
      </div>
      <div class="slider-inner vertical-middle center pt-20">
        <div class="container">
          <div class="banner-heading">
            <h1><span>Fundraising</span>Simple. Fast. Secure.</h1>
          </div>
          <div class="home-banner-cta">
            <a href="https://accounts.mchanga.africa/signup" class="button"
              >Start Today</a
            >
            <SearchBar
              id="typeahead_id"
              placeholder="Find a Fundraiser..."
              :items="campaignList"
              :minInputLength="1"
              :itemProjection="itemProjectionFunction"
              @selectItem="selectItemEventHandler"
              @onInput="onInputEventHandler"
              @onFocus="onFocusEventHandler"
              @onBlur="onBlurEventHandler"
            />
          </div>
          <div class="home-banner-statistic z-50">
            <div class="row">
              <div class="col-md-4">
                <div class="banner-statistic-item">
                  <img
                    src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117700/web/ic-fundraiser-started.svg"
                    class="statistic-icon"
                  />
                  <div class="statistic-text">
                    <div class="counter">
                      <number
                        class="bold transition"
                        :from="numberFrom"
                        :format="theFormat"
                        :to="numberFundraisers"
                        :duration="duration / 2"
                        easing="Circ.easeOut"
                      />
                    </div>
                    <p>Fundraising Campaigns Started</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="banner-statistic-item">
                  <img
                    src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117711/web/ic-supporters.svg"
                    class="statistic-icon"
                  />
                  <div class="statistic-text">
                    <div class="counter">
                      <number
                        class="bold transition"
                        :from="numberFrom"
                        :format="theFormat"
                        :to="numberDonations"
                        :duration="duration"
                        easing="Circ.easeOut"
                      />
                    </div>
                    <p>Supporters</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="banner-statistic-item">
                  <img
                    src="https://res.cloudinary.com/changa/image/upload/f_auto,h_55,q_auto:eco/v1689147969/web/cng-globe.png"
                    class="statistic-icon"
                  />
                  <div class="statistic-text">
                    <div class="counter">
                      <number
                        class="bold transition"
                        :from="1"
                        :format="theFormat"
                        :to="139"
                        :duration="duration / 4"
                        easing="Circ.easeOut"
                      />
                    </div>
                    <p>Countries</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="content">
      <div class="pb-10">
        <div class="section nobg nopadding notopmargin home-why-sec">
          <div class="container">
            <div class="heading-block center">
              <h2>Why M-Changa?</h2>
            </div>
            <div class="row">
              <div class="col-md-3 col-6" v-for="v in services" :key="v">
                <div class="home-why-item">
                  <img class="mx-auto" :src="v.src" />
                  <h4>{{ v.text }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <EasySteps />
        <Featured />
        <QquestionBottom />
        <FooterV />
      </div>
    </section>
  </div>
</template>
<script>
import { Head } from '@vueuse/head';
import SearchBar from '../components/SearchBar.vue';
import { lazyLoadComponent } from '../utils/lazy-load-components';
import loader from '../components/loader.vue';

export default {
  components: {
    SearchBar,
    Head,
    EasySteps: lazyLoadComponent({
      componentLoader: () => import('../components/EasySteps.vue'),
      loading: loader,
    }),
    Featured: lazyLoadComponent({
      componentLoader: () => import('../components/Featured.vue'),
      loading: loader,
    }),
    QquestionBottom: lazyLoadComponent({
      componentLoader: () => import('../components/QuestionBottom.vue'),
      loadingComponent: loader,
    }),
    FooterV: lazyLoadComponent({
      componentLoader: () => import('../components/Footer/index.vue'),
      loadingComponent: loader,
    }),
  },
  data() {
    return {
      services: [
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117711/web/ic-why-1.png',
          text: 'Easy set up and management',
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117712/web/ic-why-2.png',
          text: 'Mobile money donations',
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117712/web/ic-why-3.png',
          text: 'Share campaign with a single click',
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117712/web/ic-why-4.png',
          text: 'Automated campaign reports',
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117712/web/ic-why-5.png',
          text: 'Enhance donations security',
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117712/web/ic-why-6.png',
          text: 'Flexible withdrawal options',
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117713/web/ic-why-7.png',
          text: 'Live customer care',
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117713/web/ic-why-8.png',
          text: 'Global donation options',
        },
      ],
      changaStats: {},
      numberFrom: 0,
      numberFundraisers: 0,
      numberDonations: 0,
      npsScore: 0,
      duration: 1,
      scaleClass: false,
      campaignList: [],
      facebookID: process.env.VUE_APP_FACEBOOK_ID,
      currentYear: new Date().getFullYear(),
    };
  },

  async created() {
    this.homeData();
  },
  methods: {
    homeData: async function () {
      fetch(process.env.VUE_APP_ROOT_API + `/service/fundraiser/home-stats`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.changaStats = data;
          this.numberFundraisers = data.all_accounts;
          this.numberDonations = data.all_donations;
          this.npsScore = data.nps_score;
          this.currencyConfig = data.currencyConfig;
        });
      fetch(process.env.VUE_APP_ROOT_API + `/service/fundraiser/search`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.campaignList = data;
        });
    },
  },
};
</script>
<style scoped>
#modh2 {
  padding: 0;
  margin: 0;
}

#modalcontainer {
  background-color: rgb(58, 95, 11, 0.8) !important;
}
@media (max-width: 675px) {
  .home-why-sec .heading-block {
    margin: 100px 0 50px !important;
  }
}
</style>
