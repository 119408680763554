<template>
  <div id="top-bar" class="bg-white">
    <div class="container clearfix">
      <div class="topbar-content flex items-center">
        <div class="header-social-wrap pt-2.5">
          <a v-for="item in socials" :key="item.text" :href="item.to">
            <img :src="item.src" />
          </a>
        </div>
        <div class="header-contact-wrap -mt-1">
          <div class="contact-item">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-phone.png"
            />
            <span
              ><a :href="'tel:' + this.supportPhone">{{
                this.supportPhone
              }}</a></span
            >
          </div>
          <div class="contact-item">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-mail.png"
            />
            <span
              ><a :href="'mailto:' + this.supportEmail">{{
                this.supportEmail
              }}</a></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultLanguage: 'en',
      socials: [
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/facebook.png',
          text: 'Facebook',
          to: this.facebookLink,
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/twitter.png',
          text: 'Twitter',
          to: this.twitterLink,
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/linkedin.png',
          text: 'LinkedIn',
          to: this.linkedinLink,
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/youtube.png',
          text: 'Youtube',
          to: this.youtubeLink,
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/instagram.png',
          text: 'Instagram',
          to: this.instagramLink,
        },
      ],
    };
  },
};
</script>
<style>
.contact-item a {
  color: #3f3f41;
}
</style>
